import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { bool, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutTableData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import Pagination from 'components/Pagination'
import Tooltip from 'components/Tooltip'

import {
  DATAKEY_TYPES,
  DEFAULT_TABLE_PAGE_SIZE,
  PACE_SELLOUT_MANUFACTURER_FILTERS,
  SECTOR_LEVELS,
  SORTING_DIRECTION
} from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import StoreInfoTooltip from '../StoreInfoTooltip'

import AmplifySelloutTableRowHeaderToggle, { displayOptTableLabels } from './AmplifySelloutTableRowHeaderToggle'

const TIME_COLS = {
  rolling: [
    {
      field: 'lShort',
      headerName: 'LW '
    },
    {
      field: 'vpShort',
      headerName: 'vs. PW'
    },
    {
      field: 'lMid',
      headerName: 'L4'
    },
    {
      field: 'vpMid',
      headerName: 'vs. P4'
    },
    {
      field: 'lLong',
      headerName: 'L13'
    },
    {
      field: 'vpLong',
      headerName: 'vs. P13'
    }
  ],
  pointInTime: [
    {
      field: 'lShort',
      headerName: 'CTD'
    },
    {
      field: 'vpShort',
      headerName: 'vs. PC'
    },
    {
      field: 'lMid',
      headerName: 'QTD'
    },
    {
      field: 'vpMid',
      headerName: 'vs. PQ'
    },
    {
      field: 'lLong',
      headerName: 'YTD'
    },
    {
      field: 'vpLong',
      headerName: 'vs. PY'
    }
  ]
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty
const NRT_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.nrt.pos

const ManufacturerDropdown = ({ currentProductType, currentChannel, onChange, value, currentProportion }) => {
  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === 'fmc') return FMC_OPTIONS
    if (currentProductType === 'nrt') return NRT_OPTIONS
    if (currentChannel === 'specialty') return VAPE_SPECIALTY_OPTIONS

    return VAPE_POS_OPTIONS
  }, [currentChannel, currentProductType])

  const allOptions =
    currentProportion === 'share'
      ? MANUFACTURERS_DROPDOWN_OPTIONS
      : [{ label: 'All', value: 'all' }, ...MANUFACTURERS_DROPDOWN_OPTIONS]

  return <Dropdown onChange={onChange} value={value} options={allOptions} />
}

ManufacturerDropdown.propTypes = {
  currentProductType: string,
  currentChannel: string,
  onChange: func,
  value: string,
  currentProportion: string
}

const defaultGeographyBySectorLevel = {
  [SECTOR_LEVELS.CUSTOMER]: 'brand',
  [SECTOR_LEVELS.TERRITORY]: 'headoffice',
  [SECTOR_LEVELS.DISTRICT]: 'territory',
  [SECTOR_LEVELS.REGION]: 'province',
  [SECTOR_LEVELS.NATIONAL]: 'region'
}

const manufacturerByProductType = {
  fmc: 'ITCAN',
  vape: 'Vuse',
  nrt: 'Zonnic'
}

const AmplifySelloutTableCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  unitOfMeasure,
  fetchAmplifySelloutGeographyData,
  selloutDataLoading
}) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [manufacturer, setManufacturer] = useState(manufacturerByProductType[currentProductType])
  const [geography, setGeography] = useState(defaultGeographyBySectorLevel[selectedLevel])
  const [page, setPage] = useState(1)
  const [error, setError] = useState()

  const defaultSortColumn =
    selectedLevel !== SECTOR_LEVELS.CUSTOMER ? 'contribution' : currentTimeDisplay === 'rolling' ? 'lLong' : 'lShort'

  const [sortBy, setSortBy] = useState({ column: defaultSortColumn, order: SORTING_DIRECTION.DESC })

  const defaultSortAndPageData = {
    limit: DEFAULT_TABLE_PAGE_SIZE,
    sortColumn: sortBy.column,
    sortDirection: sortBy.order
  }

  const generateCols = ({ rowHeader, currentTimeDisplay, selectedLevel }) => {
    const baseCols = [
      {
        field: 'rowHeader',
        headerName: displayOptTableLabels[rowHeader]
      }
    ]

    const baseColsForSectorLevel =
      selectedLevel === SECTOR_LEVELS.CUSTOMER
        ? baseCols
        : baseCols.concat({
            field: 'contribution',
            headerName: 'Contribution'
          })

    const colsForVapeChannelCategory = showDisposableUnitsCtd
      ? [
          {
            field: 'disposableUnitsVTarget',
            headerName: '(vs target)'
          }
        ]
      : []

    const disableAWR = !config.featureFlags.awr || (currentProportion === 'share' && currentTimeDisplay === 'rolling')

    const awrCols = !disableAWR
      ? [
          {
            field: 'awr4',
            headerName: 'AWR4'
          },
          {
            field: 'awr13',
            headerName: 'AWR13'
          }
        ]
      : []

    const trendCols = [{ field: 'l13Trend', headerName: translate('app.trendedView'), isLarge: true }]

    return baseColsForSectorLevel
      .concat(colsForVapeChannelCategory)
      .concat(TIME_COLS[currentTimeDisplay])
      .concat(awrCols)
      .concat(trendCols)
  }

  const makeRow = (
    { sectorLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter },
    {
      id,
      geo,
      customer,
      address,
      ownershipType,
      contribution,
      lShort,
      vpShort,
      lMid,
      vpMid,
      lLong,
      vpLong,
      l13Trendline,
      ccTarget,
      ccTargetActuals,
      linkTo,
      awr4,
      awr13
    }
  ) => {
    const displayName = geography === 'sku' ? `${geo} - ${id}` : geography === SECTOR_LEVELS.CUSTOMER ? customer : geo

    const row = {
      rowHeader:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={displayName}
            customerName={displayName}
            erp={id}
            address={JSON.parse(address)}
            ownershipType={ownershipType}
            linkTo={linkTo}
          />
        ) : (
          <GeoTableLineHeader name={displayName} linkTo={linkTo} />
        ),
      lShort: (
        <Tooltip isNumber hint={tooltipFormatter(lShort)}>
          {isNull(lShort) ? '-' : dataFormatter(lShort)}
        </Tooltip>
      ),
      vpShort: (
        <Tooltip isNumber={!isNull(vpShort)} hint={tooltipFormatter(vpShort)}>
          {isNull(vpShort) ? '-' : <DataVariation variation={vpShort} formatter={dataFormatter} />}
        </Tooltip>
      ),
      lMid: (
        <Tooltip isNumber={!isNull(lMid)} hint={tooltipFormatter(lMid)}>
          {isNull(lMid) ? '-' : dataFormatter(lMid)}
        </Tooltip>
      ),
      vpMid: (
        <Tooltip isNumber={!isNull(vpMid)} hint={tooltipFormatter(vpMid)}>
          {isNull(vpMid) ? '-' : <DataVariation variation={vpMid} formatter={dataFormatter} />}
        </Tooltip>
      ),
      lLong: (
        <Tooltip isNumber={!isNull(lLong)} hint={tooltipFormatter(lLong)}>
          {isNull(lLong) ? '-' : dataFormatter(lLong)}
        </Tooltip>
      ),
      vpLong: (
        <Tooltip isNumber={!isNull(vpLong)} hint={tooltipFormatter(vpLong)}>
          {isNull(vpLong) ? '-' : <DataVariation variation={vpLong} formatter={dataFormatter} />}
        </Tooltip>
      ),
      awr4: (
        <Tooltip isNumber={!isNull(awr4)} hint={tooltipFormatter(awr4)}>
          {isNull(awr4) ? '-' : dataFormatter(awr4)}
        </Tooltip>
      ),
      awr13: (
        <Tooltip isNumber={!isNull(awr13)} hint={tooltipFormatter(awr13)}>
          {isNull(awr13) ? '-' : dataFormatter(awr13)}
        </Tooltip>
      ),
      l13Trend: <DataGraph color="#53CCF8" data={l13Trendline} dataFormatter={dataFormatter} />
    }

    if (sectorLevel !== SECTOR_LEVELS.CUSTOMER) {
      row.contribution = formatPercent(contribution, { convertDecimal: true })
    }

    if (showDisposableUnitsCtd) {
      row.disposableUnitsVTarget =
        !ccTarget || geography === 'sku' ? (
          '-'
        ) : (
          <Tooltip isNumber hint={formatNumber(ccTargetActuals - ccTarget)}>
            <DataVariation variation={ccTargetActuals - ccTarget} format={formatCompactNumber} />
          </Tooltip>
        )
    }

    return row
  }

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  useLayoutEffect(() => {
    if (currentProportion === 'share' && manufacturer === 'all')
      setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProportion])

  useLayoutEffect(() => {
    setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProductType, currentChannel])

  useEffect(() => {
    setPage(1)
  }, [currentSector?.id, selectedLevel, currentProductType, geography, manufacturer, currentVapeCategory, sortBy])

  const tableFilters = useMemo(() => {
    if (currentProductType === 'vape') return { manufacturer, vapeCategory: currentVapeCategory }
    if (currentProductType === 'fmc') return { manufacturer }
    if (currentProductType === 'nrt') return { manufacturer }
  }, [currentProductType, currentVapeCategory, manufacturer])

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const dataKey = useMemo(() => {
    if (!geography) return

    const dataKeyToUse = DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY

    return createDataKey(dataKeyToUse, {
      sectorType: selectedLevel,
      sectorId: currentSector[selectedLevel]?.id,
      productType: currentProductType,
      channel: currentChannel,
      filters: tableFilters,
      geography,
      dataType,
      proportion: currentProportion,
      timeDisplay: currentTimeDisplay,
      unitOfMeasure,
      offset,
      columnSorted: sortBy.column,
      sortDirection: sortBy.order
    })
  }, [
    currentSector,
    selectedLevel,
    currentProductType,
    currentChannel,
    dataType,
    geography,
    currentProportion,
    currentTimeDisplay,
    tableFilters,
    unitOfMeasure,
    offset,
    sortBy
  ])

  useEffect(() => {
    if (currentSector[selectedLevel]?.id && geography && dataKey) {
      setError(null)

      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          productType: currentProductType,
          channel: currentChannel,
          geography,
          dataType,
          proportion: currentProportion,
          timeDisplay: currentTimeDisplay,
          unitOfMeasure,
          offset,
          filters: tableFilters,
          ...defaultSortAndPageData
        },
        dataKey
      ).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [dataKey])

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const showDisposableUnitsCtd = useMemo(() => {
    return (
      ['allConsumables', 'disposables', 'closedConsumables'].includes(currentVapeCategory) &&
      currentChannel === 'pos' &&
      currentProductType === 'vape'
    )
  }, [currentVapeCategory, currentChannel, currentProductType])

  const columns = generateCols({ rowHeader: geography, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd })

  const selloutData = useSelector((state) =>
    amplifySelloutTableData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentVapeCategory,
      geography,
      currentManufacturer: manufacturer,
      dataType,
      unitOfMeasure,
      offset,
      ...defaultSortAndPageData
    })
  )

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: '-' })
      : currentMetric === 'cost'
      ? (v) => formatCompactCurrency(v, { nullDisplay: '-' })
      : (v) => formatCompactNumber(v, { nullDisplay: '-' })

  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: null })
      : currentMetric === 'cost'
      ? (v) => formatCurrency(v, { nullDisplay: null })
      : (v) => formatNumber(v, { nullDisplay: null })

  const rows =
    selloutData?.map((v) => {
      return makeRow(
        { sectorLevel: selectedLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter },
        v
      )
    }) || []

  const hasReachedLastPage = isEmpty(selloutData) || selloutData.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  const cardProps = {
    title: `${displayOptTableLabels[geography]} Performance`,
    span,
    displayAmplify: false
  }

  return (
    <Card
      {...cardProps}
      headerActions={[
        <ManufacturerDropdown
          key="amplify-sell-out-table-manufacturer-opts"
          onChange={(e) => setManufacturer(e.target.value)}
          value={manufacturer}
          currentProductType={currentProductType}
          selectedLevel={selectedLevel}
          currentChannel={currentChannel}
          currentProportion={currentProportion}
        />,
        <AmplifySelloutTableRowHeaderToggle
          key="amplify-sell-out-geography"
          geography={geography}
          setGeography={setGeography}
          currentChannel={currentChannel}
        />
      ]}
      actions={
        !isLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="sell-out-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={selloutDataLoading || isLoading}
        error={error}
        rows={rows}
        columns={columns}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['l13Trend']}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifySelloutTableCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  unitOfMeasure: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutTableCard)
