import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { func, number, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchSellinGeography } from 'store/Sellin/actions'
import { sellinGeographicalData } from 'store/Sellin/selectors'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import Pagination from 'components/Pagination'
import SegmentControl from 'components/SegmentControl'
import Tooltip from 'components/Tooltip'

import {
  CURRENT_COMPARISONS_OPTIONS,
  DATAKEY_TYPES,
  DEFAULT_TABLE_PAGE_SIZE,
  DISABLE_TOGO_PERIOD,
  PERIOD_FILTERS,
  PLACEHOLDERS,
  SECTOR_LEVELS
} from 'utils/constants'
import { formatCompactNumber, formatGap, formatNumber, formatPercent } from 'utils/formatters'
import { createDataKey, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

const OrderCompletionBar = ({ orderCompletion, completedOrders, completedGap }) => {
  const completedPercent = Math.min(orderCompletion * 100, 100)

  return (
    <div className="flex items-center">
      <div className="h-4 w-32 rounded bg-slate-100">
        <div className="h-full rounded-md bg-brand" style={{ width: `${completedPercent}%` }} />
      </div>
      <div className="pl-3">
        {completedOrders} / {completedGap || 0}
      </div>
    </div>
  )
}

OrderCompletionBar.propTypes = {
  orderCompletion: number,
  completedOrders: number,
  completedGap: number
}

const AmplifySellinTableCard = ({ span, fetchSellinGeography, vapeCategory, unitOfMeasure }) => {
  const { translate } = useContext(LangContext)
  const { sectorId, sectorType } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: translate(`period.${p}`)
  }))

  const [range, setRange] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [period, setPeriod] = useState(translatedPeriods[0].value)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'contribution', order: 'desc' })

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const updateGeography = (newGeography) => {
    setGeography(newGeography)
    setPage(1)
  }

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const noTargets =
    geography === 'sku' || ((geography === 'brand' || geography === 'brand_variant') && currentProductType !== 'vape')

  const COLS = [
    {
      field: 'name',
      headerName: 'Geo'
    },
    {
      field: 'contribution',
      headerName: translate('sellIn.contribution')
    },
    {
      field: 'booked',
      headerName: translate('sellIn.booked')
    },
    {
      field: 'gap',
      headerName: translate('sellIn.gap'),
      hideColumn: noTargets
    },
    {
      field: 'vstarget',
      headerName: translate('sellIn.vsTarget'),
      hideColumn: noTargets
    },
    {
      field: 'vssply',
      headerName: translate('sellIn.vsSPLY')
    },
    {
      field: 'awr4',
      headerName: translate('app.awr4')
    },
    {
      field: 'awr13',
      headerName: translate('app.awr13')
    },
    {
      field: 'bookedtrend',
      headerName: translate('sellIn.bookedL13'),
      isLarge: true
    }
  ]

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_IN.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    currentProductType,
    vapeCategory,
    unitOfMeasure,
    period,
    range,
    offset,
    columnSorted: sortBy.column,
    sortDirection: sortBy.order
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const geographyData = useSelector((state) =>
    sellinGeographicalData(state, {
      activeProductType: currentProductType,
      geography,
      vapeCategory,
      unitOfMeasure,
      period,
      range,
      offset,
      columnSorted: sortBy?.column,
      sortDirection: sortBy?.order
    })
  )

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  COLS[0].headerName = translate(displayOptTableLabels(geography))

  useEffect(() => {
    setPage(1)

    if (sectorType && sectorId) {
      setError(null)

      fetchSellinGeography(
        {
          sectorType,
          sectorId,
          geography,
          period,
          currentProductType,
          vapeCategory,
          unitOfMeasure,
          range,
          offset,
          limit: DEFAULT_TABLE_PAGE_SIZE,
          sortBy: sortBy?.column,
          sortDirection: sortBy?.order
        },
        dataKey
      ).catch((err) => setError(err))
    }
  }, [sectorId, sectorType, geography, currentProductType, period, vapeCategory, unitOfMeasure, range, sortBy])

  useEffect(() => {
    if (sectorType && sectorId) {
      setError(null)

      fetchSellinGeography(
        {
          sectorType,
          sectorId,
          geography,
          period,
          currentProductType,
          vapeCategory,
          unitOfMeasure,
          range,
          offset,
          limit: DEFAULT_TABLE_PAGE_SIZE,
          sortBy: sortBy?.column,
          sortDirection: sortBy?.order
        },
        dataKey
      ).catch((err) => setError(err))
    }
  }, [offset])

  const tableData = useMemo(() => {
    if (isGeographyLoading) return []

    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map(
        ({
          id,
          linkTo,
          name,
          contribution,
          vsTarget,
          booked,
          customerName,
          erp,
          address,
          ownershipType,
          gap,
          vsLastYear,
          orderCompletion,
          orderCompletionActuals,
          orderCompletionTargets,
          trended,
          awr4,
          awr13
        }) => {
          const displayName = geography === 'sku' ? `${name} - ${id}` : name || '-'

          return {
            name:
              geography === SECTOR_LEVELS.CUSTOMER ? (
                <StoreInfoTooltip
                  displayName={displayName}
                  customerName={customerName}
                  erp={erp}
                  address={address}
                  ownershipType={ownershipType}
                  linkTo={linkTo}
                />
              ) : (
                <GeoTableLineHeader name={displayName} linkTo={linkTo} />
              ),
            contribution: contribution
              ? formatPercent(contribution, { convertDecimal: true })
              : PLACEHOLDERS.NO_VALUE_PERCENT,
            booked: (
              <Tooltip isNumber hint={booked}>
                {booked ? formatCompactNumber(booked) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            gap: (
              <Tooltip isNumber hint={gap}>
                {!isNaN(gap) ? formatGap(gap, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            vstarget: (
              <Tooltip isNumber hint={+vsTarget * 100}>
                {+vsTarget ? formatPercent(+vsTarget, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT}
              </Tooltip>
            ),
            vssply: <DataVariation peer variation={+vsLastYear ? +vsLastYear * 100 : 0} variationBefore isPercent />,
            bookedtrend: <DataGraph color="#53CCF8" data={trended} />,
            orderCompletion: (
              <OrderCompletionBar
                orderCompletion={orderCompletion}
                completedOrders={orderCompletionActuals}
                completedGap={orderCompletionTargets}
              />
            ),
            awr4: (
              <Tooltip isNumber hint={formatNumber(awr4)}>
                {!isNaN(awr4) ? formatGap(awr4, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            awr13: (
              <Tooltip isNumber hint={formatNumber(awr13)}>
                {!isNaN(awr13) ? formatGap(awr13, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            )
          }
        }
      )
    }

    return []
  }, [geographyData, geography, isGeographyLoading])

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key="period"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          options={translatedPeriods}
        />,
        <SegmentControl
          key="range"
          name="range"
          onChange={(e) => setRange(e.target.value)}
          value={range}
          options={CURRENT_COMPARISONS_OPTIONS}
          disabled={DISABLE_TOGO_PERIOD.includes(period)}
        />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={updateGeography}
          includeBrandVariant
          includeBrand
          includeOwnership
          includeSku
        />
      ]}
      actions={
        !isGeographyLoading && (tableData.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyLoading}
                disableNextButton={tableData.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyLoading}
        error={error}
        rows={tableData}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['bookedtrend']}
        columns={COLS.filter(({ hideColumn }) => !hideColumn)}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifySellinTableCard.propTypes = {
  span: number,
  fetchSellinGeography: func,
  vapeCategory: string,
  unitOfMeasure: string
}

export default connect(null, { fetchSellinGeography })(AmplifySellinTableCard)
