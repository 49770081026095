import React, { useContext, useState } from 'react'
import config from 'config'
import { number, shape } from 'prop-types'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import ActionTable from 'components/close/ActionTable'
import EmptyState from 'components/EmptyState'
import ItemWithRating from 'components/evaluate/ItemWithRating'
import Reminders from 'components/evaluate/Reminders'

const ObjectivesCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  const initializeRatings = () => {
    const labels = [
      'Race to Cycle Target',
      'Spotting Hidden Opportunities',
      'Unlock The Sell Out Surge',
      'Fine Tune Pricing Opportunities'
    ]
    return labels.map((label) => ({ label, rating: null }))
  }

  const [performance, setPerformance] = useState(initializeRatings())

  const handleRating = (label, newRating) => {
    setPerformance((prevPerformance) =>
      prevPerformance.map((item) =>
        item.label === label ? { ...item, rating: item.rating === newRating ? null : newRating } : item
      )
    )
  }

  const objectives = {
    sellIn: {
      title: translate('app.sellIn'),
      CTA: translate('evaluate.objectives.CTA.template', { amount: 5 }),
      items: [
        { label: 'Need to complete sell-in for 12 more Vuse products', completed: false },
        { label: 'Follow up on 3 pending Extra program activations', completed: false },
        { label: 'Verify distribution of new Vuse flavors', completed: false },
        { label: 'Book sell-in orders for 5 key accounts', completed: false },
        { label: 'Follow up on 3 pending sell-in commitments', completed: false }
      ]
    },
    priceCheck: {
      title: translate('app.priceCheck'),
      CTA: translate('evaluate.objectives.CTA.template', { amount: 2 }),
      items: [
        { label: 'Just 10 products left to acheive 100% full price capture!', completed: false },
        { label: 'Only 5 products remain to fully align with our price strategy!', completed: false },
        { label: '8 products to go to achieve 100% verification!', completed: true }
      ]
    },
    survey: {
      title: translate('app.surveys'),
      CTA: translate('evaluate.objectives.CTA.template', { amount: 3 }),
      items: [
        { label: 'Complete Extra program survey for 5 stores', completed: false },
        { label: 'Update Vuse product survey for 3 locations', completed: false },
        { label: 'Complete 2024 Vuse survey for 5 stores', completed: false },
        { label: 'Finish competitor activity survey for 2 stores', completed: true }
      ]
    },
    extraFunds: {
      title: translate('app.extraFunds'),
      CTA: translate('evaluate.objectives.CTA.template', { amount: 1 }),
      items: [
        { label: 'Complete 2024 Extra program survey for 5 stores', completed: false },
        { label: 'Follow up on 3 pending Extra program activations', completed: true }
      ]
    }
  }

  return (
    <Card title={translate('evaluate.objectives')} span={span} displayAmplify={false}>
      {!config.featureFlags.closeOpportunities || !config.featureFlags.closeOpportunitiesObjectives ? (
        <EmptyState title={translate('common.comingSoon')} />
      ) : (
        <>
          {/* Performance */}
          <div className="flex flex-col gap-4">
            <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.performance')}</h3>
            <div className="flex flex-col gap-3 pl-4">
              {performance.map((item) => (
                <ItemWithRating
                  key={item.label}
                  item={item.label}
                  rating={item.rating}
                  handleRating={(newRating) => handleRating(item.label, newRating)}
                />
              ))}
            </div>
          </div>

          <hr className="border-slate-200" />

          {/* Objectives */}
          <div className="flex flex-col gap-6">
            <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.todaysVisit')}</h3>
            <div className="space-between flex gap-4">
              {Object.entries(objectives).map(([key, objective]) => {
                const completed = objective.items.filter((item) => item.completed).length
                const total = objective.items.length

                return (
                  <div key={key} className="flex items-center justify-between">
                    <ActionTable key={key} variant={key} title={objective.title} completed={completed} total={total} />
                  </div>
                )
              })}
            </div>
          </div>

          <hr className="border-slate-200" />

          {/* Reminders */}
          <Reminders />
        </>
      )}
    </Card>
  )
}

ObjectivesCard.propTypes = {
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

export default ObjectivesCard
