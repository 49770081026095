import camelCase from 'lodash/camelCase'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import pick from 'lodash/pick'
import startCase from 'lodash/startCase'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { PACE_SELLOUT_MANUFACTURER_FILTERS, SECTOR_LEVELS } from 'utils/constants'
import { sortArrayByDirection } from 'utils/helpers'
import { SELLOUT_STATE_KEYS } from 'utils/paceStateKeys'

const manufacturerFromProps = (state, props) => props.currentManufacturer
const productTypeFromProps = (state, props) => props.activeProductType || props.currentProductType
const currentChannelFromProps = (state, props) => props.currentChannelFilter || props.currentChannel || props.channel
const unitOfMeasureFromProps = (state, props) => props.unitOfMeasure
const dataTypeFromProps = (state, props) => props.dataType
const columnSortedFromProps = (state, props) => props.columnSorted || props.sortColumn
const sortDirectionFromProps = (state, props) => props.sortDirection

const currentProportionFromProps = (state, props) => props.currentProportion
const currentTimeDisplayFromProps = (state, props) => props.currentTimeDisplay
const currentVapeCategoryFromProps = (state, props) => props.currentVapeCategory
const geographyFromProps = (state, props) => props.geography
const sortAndPageProps = (state, props) => pick(props, ['sortColumn', 'sortDirection', 'offset', 'limit'])
const selectedLevelFromProps = (state, props) => props.selectedLevel

const VAPE_SPECIALTY_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty.map(({ value }) => value)
const VAPE_POS_BRANDS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos.map(({ value }) => value)
// const FMC_MANUFACTURERS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos.map(({ value }) => value)

export const selloutDataFromStore = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  dataTypeFromProps,
  unitOfMeasureFromProps,
  dataSourceFromSectorLevel,
  (activeProductType, channel, dataType, unitOfMeasure, sector) => {
    if (!sector) return null
    const stateKey = SELLOUT_STATE_KEYS.MAIN({ activeProductType, channel, dataType, unitOfMeasure })
    const selloutData = sector[stateKey]
    return selloutData
  }
)

function getVapeCategoriesForSelection(vapeCategory) {
  if (!vapeCategory) return null
  const consumables = ['openConsumables', 'closedConsumables', 'disposables']
  if (vapeCategory === 'allConsumables') return consumables
  return [vapeCategory]
}

export const brandManufacturerGroupedSelloutData = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  currentVapeCategoryFromProps,
  selloutDataFromStore,
  (activeProductType, activeChannelFilter, vapeCategory, amplifySelloutSectorData) => {
    if (isEmpty(amplifySelloutSectorData)) return null
    const isVape = activeProductType === 'vape'
    const isNrt = activeProductType === 'nrt'
    const vapeBrandsForChannel = activeChannelFilter === 'specialty' ? VAPE_SPECIALTY_BRANDS : VAPE_POS_BRANDS
    const vapeCategoriesToInclude = getVapeCategoriesForSelection(vapeCategory)
    const filteredData = isVape
      ? amplifySelloutSectorData.filter(
          (d) => !vapeCategory || vapeCategoriesToInclude.includes(camelCase(d.vapeCategory))
        )
      : amplifySelloutSectorData // .filter(({ manufacturer }) => FMC_MANUFACTURERS.includes(manufacturer)) // PER CONVERSATION WITH SUNGJIN 2024-03-25 total is not restricted to specified manufacturers

    return groupBy(filteredData, ({ manufacturer, brand }) => {
      if (isVape)
        return vapeBrandsForChannel.some((b) => b.toUpperCase() === brand.toUpperCase()) ? brand.toUpperCase() : 'OTHER'
      if (isNrt)
        return AMPLIFY_NRT_BRANDS.some((b) => b.toUpperCase() === brand.toUpperCase()) ? brand.toUpperCase() : 'OTHER'
      return manufacturer?.toUpperCase() || 'OTHER'
    })
  }
)

function rechartsFormat({ result, isShare }) {
  const multiplier = isShare ? 100 : 1
  return [
    {
      data: +result?.l13w1 * multiplier || null,
      name: moment()
        .day(-7 * 13)
        .format('M/D')
    },
    {
      data: +result?.l13w2 * multiplier || null,
      name: moment()
        .day(-7 * 12)
        .format('M/D')
    },
    {
      data: +result?.l13w3 * multiplier || null,
      name: moment()
        .day(-7 * 11)
        .format('M/D')
    },
    {
      data: +result?.l13w4 * multiplier || null,
      name: moment()
        .day(-7 * 10)
        .format('M/D')
    },
    {
      data: +result?.l13w5 * multiplier || null,
      name: moment()
        .day(-7 * 9)
        .format('M/D')
    },
    {
      data: +result?.l13w6 * multiplier || null,
      name: moment()
        .day(-7 * 8)
        .format('M/D')
    },
    {
      data: +result?.l13w7 * multiplier || null,
      name: moment()
        .day(-7 * 7)
        .format('M/D')
    },
    {
      data: +result?.l13w8 * multiplier || null,
      name: moment()
        .day(-7 * 6)
        .format('M/D')
    },
    {
      data: +result?.l13w9 * multiplier || null,
      name: moment()
        .day(-7 * 5)
        .format('M/D')
    },
    {
      data: +result?.l13w10 * multiplier || null,
      name: moment()
        .day(-7 * 4)
        .format('M/D')
    },
    {
      data: +result?.l13w11 * multiplier || null,
      name: moment()
        .day(-7 * 3)
        .format('M/D')
    },
    {
      data: +result?.pw * multiplier || null,
      name: moment()
        .day(-7 * 2)
        .format('M/D')
    },
    {
      data: +result?.lw * multiplier || null,
      name: moment().day(-7).format('M/D')
    }
  ]
}

const COLORS = ['#F5BE41', '#4FAEE6', '#B4C941', '#ff5050', '#9999ff', '#669999']

function getAmplifyTotals(data, timeKeys, staticData = null) {
  return timeKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: data.some((d) => !isNull(d[key])) ? sumBy(data, (d) => +d[key]) : null
    }),
    { ...staticData }
  )
}

export const amplifySelloutSOMData = createSelector(
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  columnSortedFromProps,
  sortDirectionFromProps,
  brandManufacturerGroupedSelloutData,
  (proportion, timeDisplay, columnToSortBy, sortDirection, amplifyData) => {
    if (isEmpty(amplifyData)) return null
    const mainPeriod = timeDisplay === 'rolling' ? 'l4' : 'cc'
    const previousPeriod = timeDisplay === 'rolling' ? 'p4' : 'pc'

    const manufacturerTotals = Object.entries(amplifyData).map(([manufacturer, manufacturerData], idx) =>
      getAmplifyTotals(manufacturerData, [mainPeriod, previousPeriod, 'l4'], {
        name: startCase(manufacturer),
        fill: COLORS[idx]
      })
    )

    const mainPeriodTotal = sumBy(manufacturerTotals, (t) => +t[mainPeriod]) || 1
    const awr4PeriodTotal = sumBy(manufacturerTotals, (t) => +t.l4) || 1
    const previousPeriodTotal = sumBy(manufacturerTotals, (t) => +t[previousPeriod]) || 1

    const totals = manufacturerTotals.map((d) => {
      const mainPeriodData = d[mainPeriod]
      const awr4PeriodData = d.l4
      const previousPeriodData = d[previousPeriod]
      const isShare = proportion === 'share'

      return isShare
        ? {
            ...d,
            mainPeriod: isNull(mainPeriodData) ? null : +(mainPeriodData / mainPeriodTotal),
            previousPeriod: isNull(previousPeriodData) ? null : previousPeriodData,
            variation:
              isNull(mainPeriodData) || isNull(previousPeriodData)
                ? null
                : +(mainPeriodData / mainPeriodTotal - previousPeriodData / previousPeriodTotal),
            ...(timeDisplay !== 'rollling' && {
              awr4: isNull(awr4PeriodData) ? null : +(awr4PeriodData / awr4PeriodTotal)
            })
          }
        : {
            ...d,
            mainPeriod: isNull(mainPeriodData) ? null : +mainPeriodData,
            previousPeriod: isNull(previousPeriodData) ? null : previousPeriodData,
            variation:
              isNull(mainPeriodData) || isNull(previousPeriodData) ? null : +(mainPeriodData - previousPeriodData),
            awr4: isNull(awr4PeriodData) ? null : +awr4PeriodData / 4
          }
    })

    return sortArrayByDirection(totals, { columnToSortBy, sortDirection, sortNulls: true })
  }
)

// const AMPLIFY_FMC_BRANDS = [
//   'du Maurier Core',
//   'Belmont',
//   'Export A',
//   "Player's Blue",
//   'Marlboro VFM',
//   'Canadian Classics',
//   'Macdonald SP',
//   'Pall Mall',
//   'John Player Standard',
//   'Next',
//   'LD',
//   'Philip Morris by PMI',
//   'Viceroy',
//   'Matinee',
//   'Vogue',
//   'B&H',
//   'Number7',
//   'Mark Ten',
//   'Quebec Classique'
// ]

const AMPLIFY_NRT_BRANDS = ['Zonnic', 'Sesh']

const formatTrendlineData = (geographyL13Totals) => {
  const { l13w1, l13w2, l13w3, l13w4, l13w5, l13w6, l13w7, l13w8, l13w9, l13w10, l13w11, pw, lw } = geographyL13Totals
  return [
    { d: +l13w1 },
    { d: +l13w2 },
    { d: +l13w3 },
    { d: +l13w4 },
    { d: +l13w5 },
    { d: +l13w6 },
    { d: +l13w7 },
    { d: +l13w8 },
    { d: +l13w9 },
    { d: +l13w10 },
    { d: +l13w11 },
    { d: +pw },
    { d: +lw }
  ]
}

export const amplifySelloutDataForGeography = createSelector(
  productTypeFromProps,
  currentChannelFromProps,
  geographyFromProps,
  dataTypeFromProps,
  manufacturerFromProps,
  currentVapeCategoryFromProps,
  currentProportionFromProps,
  currentTimeDisplayFromProps,
  unitOfMeasureFromProps,
  dataSourceFromSectorLevel,
  selectedLevelFromProps,
  sortAndPageProps,
  (
    productType,
    channel,
    geography,
    dataType,
    manufacturer,
    vapeCategory,
    proportion,
    timeDisplay,
    unitOfMeasure,
    sector,
    selectedLevelFromProps,
    sortProps
  ) => {
    if (!sector) return null
    const filters = productType !== 'vape' ? { manufacturer } : { manufacturer, vapeCategory }

    const stateKey = SELLOUT_STATE_KEYS.GEOGRAPHY({
      productType,
      channel,
      dataType,
      geography,
      proportion,
      timeDisplay,
      unitOfMeasure,
      filters,
      ...sortProps
    })
    const geographyData = sector[stateKey]

    return {
      geographyData,
      sectorDetails: { id: sector.id, name: sector.name }
    }
  }
)

export const selloutL13TrendGraphData = createSelector(
  currentProportionFromProps,
  amplifySelloutDataForGeography,
  (proportion, sectorData) => {
    const emptyResult = { awr: { awr4: null, awr13: null }, trend: rechartsFormat({ result: null }) }
    if (isEmpty(sectorData) || isEmpty(sectorData?.geographyData)) return emptyResult
    const geographyData = sectorData?.geographyData[0]
    const isShare = proportion === 'share'
    const awr = {
      awr4: isShare ? 100 * geographyData.awr4 : geographyData.awr4,
      awr13: isShare ? 100 * geographyData.awr13 : geographyData.awr13
    }
    return { awr, trend: rechartsFormat({ result: geographyData, isShare }) }
  }
)

export const amplifySelloutBrandPerfData = createSelector(
  currentTimeDisplayFromProps,
  amplifySelloutDataForGeography,
  (timeDisplay, amplifyData) => {
    if (isEmpty(amplifyData?.geographyData)) return null
    const columnsByPeriod = timeDisplay === 'rolling' ? ['lMid', 'vpMid'] : ['lShort', 'vpShort']
    return amplifyData.geographyData.map(({ variantLabel, brand, lMid, awr4, ...variantLabelData }) => ({
      name: brand || variantLabel,
      awr4: isNull(lMid) ? null : awr4,
      lMid,
      ...pick(variantLabelData, columnsByPeriod)
    }))
    // const otherAgg = test.filter(({name}) => name === 'Others').reduce((acc, other) => {
    //   return {
    //     name: 'Others',
    //     awr4: acc.awr4 + other?.awr4 || 0,
    //     lMid: acc.lMid + other?.lMid || 0,
    //     vpMid: acc.vpMid + other?.vpMid || 0,
    //     lShort: acc.lShort + other?.lShort || 0,
    //     vpShort: acc.vpShort + other?.vpShort || 0
    //   }
    // }, {
    //   name: 'Others',
    //   awr4: 0,
    //   lMid: 0,
    //   vpMid: 0,
    //   lShort: 0,
    //   vpShort: 0
    // })
    // const noOthers = test.filter(({name}) => name !== 'Others').push(otherAgg)
    // return noOthers
  }
)

export const amplifySelloutKAData = createSelector(
  currentTimeDisplayFromProps,
  amplifySelloutDataForGeography,
  (timeDisplay, amplifyData) => {
    if (isEmpty(amplifyData?.geographyData)) return null
    const columnsByPeriod = timeDisplay === 'rolling' ? ['lMid', 'vpMid'] : ['lShort', 'vpShort']
    return amplifyData.geographyData.map(({ headOffice, lMid, awr4, ...headOfficeData }) => ({
      name: headOffice,
      awr4: isNull(lMid) ? null : awr4,
      lMid,
      ...pick(headOfficeData, columnsByPeriod)
    }))
  }
)

const rowHeaderColumnMapping = {
  customer: {
    key: 'customerId',
    label: (c) => `${c.customer || c.name} (${c.customerId || c.id})`,
    linkTo: (c) => `/${SECTOR_LEVELS.CUSTOMER}/${c.customerId}/pace/amplify/sell-out`
  },
  headoffice: { key: 'headOffice', label: (d) => d.headOffice },
  territory: {
    key: 'territoryName',
    label: (d) => d.territoryName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.TERRITORY}/${d.territoryId || d.id}/pace/amplify/sell-out`
  },
  district: {
    key: 'districtName',
    label: (d) => d.districtName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.DISTRICT}/${d.districtId || d.id}/pace/amplify/sell-out`
  },
  region: {
    key: 'regionName',
    label: (d) => d.regionName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.REGION}/${d.regionId || d.id}/pace/amplify/sell-out`
  },
  subregion: { key: 'KA Subregion', label: (d) => d.subregion },
  province: { key: 'province', label: (d) => d.province },
  brand: { key: 'brand', label: (d) => d.brand },
  variantLabel: { key: 'variantLabel', label: (d) => d.variantLabel },
  sku: { key: 'productId', label: (d) => d.productName }
}

export const amplifySelloutTableData = createSelector(
  geographyFromProps,
  amplifySelloutDataForGeography,
  (activeGeography, amplifyData) => {
    if (isEmpty(amplifyData)) return null
    const rowHeaderColumn = rowHeaderColumnMapping[activeGeography]
    const rows = amplifyData?.geographyData?.map((r) => {
      return {
        id: r[rowHeaderColumn.key],
        geo: rowHeaderColumn.label(r),
        linkTo: rowHeaderColumn.linkTo && rowHeaderColumn.linkTo(r),
        l13Trendline: formatTrendlineData(r),
        ...r
      }
    })
    return rows
  }
)
